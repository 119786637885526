// src/store/modules/askQuestion.ts
import { Module } from "vuex";
import api from "@/services/api";

interface AskQuestionState {
  currentQuestion: string;
  showModal: boolean;
}

const askQuestion: Module<AskQuestionState, unknown> = {
  namespaced: true,
  state: {
    currentQuestion: "",
    showModal: false,
  },
  mutations: {
    setCurrentQuestion(state, question: string) {
      state.currentQuestion = question;
    },
    setShowModal(state, showModal: boolean) {
      state.showModal = showModal;
    },
  },
  actions: {
    async askQuestion({ commit }, question: string) {
      const answer = await api.askQuestion(question);
      commit("setCurrentQuestion", question);
      commit("setShowModal", true);
      return answer;
    },
    closeModal({ commit }) {
      commit("setShowModal", false);
    },
  },
};

export default askQuestion;
