<template>
  <div>
    <h1 class="text-3xl font-bold mb-4">{{election ? election.country + " - " + election.name : ""}}</h1>
    <p class="text-lg mb-6">
      Vous allez répondre à une série de questions pour déterminer quel candidat politique correspond le mieux à vos opinions. Prenez votre temps pour lire chaque question et choisir la réponse qui vous convient le mieux. <br/><br/> Si vous avez besoin d'une explication sur une question, cliquez sur le bouton "Besoin d'explication ?".
    </p>
    <button @click="$emit('startQuiz')" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Commencer</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QuizIntro',
  props: {
    election: Object,
  },
});
</script>
