import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('prevQuestion'))),
      disabled: _ctx.currentQuestionIndex === 0,
      class: "bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
    }, "Précédent", 8, _hoisted_2),
    (!_ctx.isLastQuestion)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('nextQuestion'))),
          class: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        }, "Suivant"))
      : _createCommentVNode("", true),
    (_ctx.isLastQuestion)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submitQuiz'))),
          class: "bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        }, "Soumettre"))
      : _createCommentVNode("", true)
  ]))
}