<template>
  <div id="app">
    <header class="bg-blue-600 text-white shadow-lg">
      <div class="container mx-auto p-4 flex justify-between items-center">
        <div class="flex items-center space-x-4">
          <router-link to="/">
            <h1 class="text-2xl font-bold">Pour Qui Je Vote</h1>
          </router-link>
        </div>
        <nav class="space-x-4">
          <!--router-link to="/" class="hover:underline">Accueil</router-link -->
          <router-link to="/about" class="hover:underline">À propos</router-link>
          <router-link to="/contact" class="hover:underline">Contact</router-link>
        </nav>
      </div>
    </header>
    <main class="container mx-auto p-4">
      <router-view></router-view>
    </main>
    <footer class="bg-gray-200 text-gray-700 py-4 mt-8">
      <p class="text-center">&copy; 2024 Pour Qui Je Vote. Tous droits réservés.</p>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
a {
  text-decoration: none;
  color: inherit;
}

header a {
  color: white;
}
</style>
