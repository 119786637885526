<template>
  <div class="mb-4 p-4 bg-white rounded shadow-md">
    <h2 class="text-xl font-bold">{{ result.candidate.name }}</h2>
    <p class="text-lg mb-2">Score: {{ result.score }}%</p>
    <div class="w-full bg-gray-200 rounded-full h-4 mb-4">
      <div :class="getProgressBarClass(result.score)" :style="{ width: result.score + '%' }" class="h-4 rounded-full"></div>
    </div>
    <p class="mb-2"><strong>Parti:</strong> {{ result.candidate.party.name }}</p>
    <!--<p class="mb-2"><strong>Caractéristiques:</strong></p>
    <ul class="list-disc list-inside mb-2">
      <li v-for="(value, key) in result.candidate.characteristics" :key="key">
        {{ key }}: {{ value }}
      </li>
    </ul> -->
    <button @click="toggleDetails" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
      {{ showDetails ? 'Masquer les détails' : 'Voir plus de détails' }}
    </button>
    <div v-if="showDetails && result.choiceDetails && result.choiceDetails.length > 0" class="mt-4 p-4 border rounded-lg bg-gray-50">
      <h3 class="text-lg font-bold mb-4">Détails des Choix</h3>
      <div v-for="(detail, index) in result.choiceDetails" :key="index" class="mb-4 p-2 border-b last:border-b-0">
        <p><strong>Question:</strong> {{ detail.question }}</p>
        <p><strong>Votre choix:</strong> {{ detail.selectedChoice }}</p>
        <p><strong>Position du candidat:</strong> {{ detail.candidateChoice }}</p>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { Result } from '@/models/Result';

export default defineComponent({
  name: 'ResultCard',
  props: {
    result: {
      type: Object as PropType<Result>,
      required: true
    }
  },
  setup() {
    const showDetails = ref(false);

    const toggleDetails = () => {
      showDetails.value = !showDetails.value;
    };

    const getProgressBarClass = (score: number) => {
      if (score >= 75) {
        return 'bg-green-500';
      } else if (score >= 50) {
        return 'bg-blue-500';
      } else if (score >= 25) {
        return 'bg-orange-500';
      } else {
        return 'bg-red-500';
      }
    };

    return {
      showDetails,
      toggleDetails,
      getProgressBarClass
    };
  }
});
</script>

<style scoped>
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.font-semibold {
  font-weight: 600;
}
.text-gray-900 {
  color: #1a202c;
}
.text-red-500 {
  color: #f56565;
}
</style>
