import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomePage.vue";
import AboutView from "../views/About.vue";
import Quiz from "../views/Quiz.vue";
import Result from "../views/Result.vue";
import Contact from "../views/Contact.vue";
import MethodologieView from '@/views/MethodologieView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/quiz/:electionId",
    name: "quiz",
    component: Quiz,
    props: true
  },
  {
    path: "/results/:visitorId/:electionId",
    name: "result",
    component: Result,
    props: true
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: '/methodologie',
    name: 'Methodologie',
    component: MethodologieView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
