<template>
  <div
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="bg-white p-8 rounded shadow-lg">
      <span class="close text-gray-600 cursor-pointer" @click="$emit('close')"
        >&times;</span
      >
      <h2 class="text-2xl font-bold mb-4">Poser une Question</h2>
      <p class="mb-4">{{ question?.text }}</p>
      <textarea
        v-model="userQuestion"
        placeholder="Entrez votre question ici"
        class="w-full p-2 border rounded mb-4"
      ></textarea>
      <button
        @click="askQuestion"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Envoyer
      </button>
      <div v-if="answer" class="mt-4">
        <h2 class="text-xl font-bold">Réponse</h2>
        <p>{{ answer }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";

export default defineComponent({
  name: "AskQuestionModal",
  props: {
    question: Object,
  },
  data() {
    return {
      userQuestion: "",
      answer: null,
    };
  },
  computed: {
    ...mapState("askQuestion", ["currentQuestion"]),
  },
  methods: {
    ...mapActions("askQuestion", ["askQuestion", "closeModal"]),
    async askQuestion() {
      if (!this.userQuestion) {
        alert("Veuillez entrer une question.");
        return;
      }

      //this.answer = await this.askQuestion(`${this.question}: ${this.userQuestion}`);
    },
  },
});
</script>

<style scoped>
/* Add any additional styles here */
</style>
