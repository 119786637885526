// src/store/index.ts
import { createStore } from "vuex";
import questions from "./modules/questions";
import results from "./modules/results";
import askQuestion from "./modules/askQuestion";
import elections from "./modules/elections";

export default createStore({
  modules: {
    questions,
    results,
    askQuestion,
    elections
  },
});
