import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e993648a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-sm rounded overflow-hidden shadow-lg bg-white p-5" }
const _hoisted_2 = { class: "px-6 py-4" }
const _hoisted_3 = { class: "font-bold text-xl mb-2" }
const _hoisted_4 = { class: "text-gray-700 text-base" }
const _hoisted_5 = { class: "text-gray-700 text-base" }
const _hoisted_6 = { class: "px-6 pt-4 pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.election.country) + " - " + _toDisplayString(_ctx.election.name), 1),
      _createElementVNode("p", _hoisted_4, " Date: " + _toDisplayString(_ctx.election.date), 1),
      _createElementVNode("p", _hoisted_5, " Tour: " + _toDisplayString(_ctx.election.round), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_router_link, {
        to: { name: 'quiz', params: { electionId: _ctx.election.id } },
        class: "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Commencer le quiz ")
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}