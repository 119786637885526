import { Module } from 'vuex';
import api from '@/services/api';
import { Result } from '@/models/Result';

interface ResultsState {
  results: Result[];
}

const results: Module<ResultsState, unknown> = {
  namespaced: true,
  state: {
    results: [],
  },
  mutations: {
    setResults(state, results: Result[]) {
      state.results = results;
    },
  },
  actions: {
    async fetchResults({ commit }, { visitorId, electionId }) {
      const results = await api.fetchResults(visitorId, electionId);
      commit('setResults', results);
    },
  },
  getters: {
    results: (state: ResultsState) => state.results,
  },
};

export default results;
