<template>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl font-bold mb-4">Résultats</h1>
    <ResultsList />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import ResultsList from '@/components/result/ResultsList.vue';
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'ResultView',
  components: {
    ResultsList
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const electionId = Number(route.params.electionId);
    const visitorId = route.params.visitorId;

    onMounted(() => {
      store.dispatch('results/fetchResults', { visitorId, electionId });
    });

    return {};
  }
});
</script>
