<template>
  <div class="container mx-auto p-4">
    <header class="mb-6">
      <h1 class="text-4xl font-bold mb-2">Méthodologie</h1>
      <p class="text-lg">
        Découvrez comment nous collectons les données et calculons les scores pour vous aider à trouver le candidat politique qui correspond le mieux à vos opinions.
      </p>
    </header>

    <section class="mb-8">
      <h2 class="text-2xl font-bold mb-4">Collecte des données</h2>
      <p class="mb-4">
        Nous collectons des données à partir de plusieurs sources pour garantir une représentation précise des candidats et de leurs positions politiques :
      </p>
      <ul class="list-disc list-inside mb-4">
        <li class="mb-2">Programmes électoraux officiels des candidats</li>
        <li class="mb-2">Votes sur les lois au Sénat et à l'Assemblée Nationale</li>
        <li class="mb-2">Déclarations publiques et plateformes de campagne</li>
      </ul>
    </section>

    <section class="mb-8">
      <h2 class="text-2xl font-bold mb-4">Calcul du score de compatibilité</h2>
      <p class="mb-4">
        Notre algorithme de calcul du score de compatibilité fonctionne de la manière suivante :
      </p>
      <ol class="list-decimal list-inside mb-4">
        <li class="mb-2">
          <strong>Réponses aux questions :</strong> Vous répondez à une série de questions qui couvrent différents sujets politiques.
        </li>
        <li class="mb-2">
          <strong>Analyse des réponses :</strong> Pour chaque question, nous comparons vos réponses avec les positions des candidats sur ces sujets. Les réponses sont pondérées selon leur importance relative et leur cohérence avec les programmes des candidats.
        </li>
        <li class="mb-2">
          <strong>Score de compatibilité :</strong> Un score de compatibilité est calculé en utilisant une matrice de correspondance entre vos réponses et celles des candidats. Le score est exprimé en pourcentage et reflète à quel point un candidat correspond à vos opinions.
        </li>
      </ol>
    </section>

    <section class="mb-8">
      <h2 class="text-2xl font-bold mb-4">Sécurité et transparence</h2>
      <p class="mb-4">
        Nous prenons la sécurité et la confidentialité de vos données très au sérieux. Toutes les réponses sont anonymisées et stockées de manière sécurisée. Nous ne partageons aucune information personnelle avec des tiers.
      </p>
      <p class="mb-4">
        La transparence est également au cœur de notre démarche. Vous pouvez consulter notre code source et notre méthodologie complète sur notre <a href="https://github.com/jacquesbagui/pourquijevote" class="text-blue-500 hover:underline">répertoire GitHub</a>.
      </p>
    </section>

    <section>
      <h2 class="text-2xl font-bold mb-4">Nous contacter</h2>
      <p class="mb-4">
        Si vous avez des questions ou des suggestions, n'hésitez pas à nous contacter à <a href="/contact" class="text-blue-500 hover:underline">Contact</a>.
      </p>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MethodologieView',
});
</script>

<style scoped>
h1,
h2 {
  color: #2c3e50;
}
p,
ul,
ol {
  color: #34495e;
}
</style>
