<template>
  <div class="mb-6 p-4 border rounded-lg shadow-md bg-white">
    <div class="flex flex-col md:flex-row items-start md:items-center">
      <p class="text-lg mb-2 md:mb-0 md:mr-4 flex-1 font-semibold text-gray-900">{{ question.text }}</p>
      <button @click="$emit('askQuestion', question)" class="ml-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mt-2 md:mt-0">Besoin d'explication ?</button>
    </div>
    <div v-for="(choice, key) in question.choices" :key="key" class="mb-2 flex items-center">
      <input
          type="radio"
          :name="'question-' + index"
          :id="key"
          :value="key"
          :checked="responses[index]?.answer === key"
          @change="$emit('updateResponse', { index, key })"
          class="mr-2"
      />
      <label :for="key" class="mr-2 cursor-pointer">{{ choice }}</label>
    </div>
    <p v-if="showError && (responses[index]?.answer == '' || responses[index]?.answer == null)" class="text-red-500">Un choix est requis.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface Question {
  text: string;
  choices: string[];
}

interface Response {
  answer: any | null;
}

export default defineComponent({
  name: 'QuizQuestion',
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    responses: {
      type: Array as PropType<Response[]>,
      required: true
    },
    showError: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style scoped>
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.font-semibold {
  font-weight: 600;
}
.text-gray-900 {
  color: #1a202c;
}
.text-red-500 {
  color: #f56565;
}
</style>
