import { Module, ActionContext } from "vuex";
import axios from "axios";
import { Election } from "@/models/Election";
import api from "@/services/api";

interface ElectionsState {
    elections: Election[];
    election: any;
}

const state: ElectionsState = {
    elections: [],
    election: null,
};

const mutations = {
    setElections(state: ElectionsState, elections: Election[]) {
        state.elections = elections;
    },
    setElection(state: ElectionsState, election: Election) {
        state.election = election;
    },
};

const actions = {
    async fetchElections({ commit }: ActionContext<ElectionsState, unknown>) {
        const elections =  await api.getElections()
        commit('setElections', elections);
    },
    async fetchElection({ commit }: ActionContext<ElectionsState, unknown>, id: number) {
        const election =  await api.getElection(id)
        commit('setElection', election);
    }
};

const getters = {
    elections: (state: ElectionsState) => state.elections,
    election: (state: ElectionsState) => state.election,
};

const elections: Module<ElectionsState, unknown> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default elections;
