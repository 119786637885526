<template>
  <div>
    <div v-if="results && results.length > 0">
      <ResultCard v-for="result in sortedResults" :key="result.candidate.id" :result="result" />
    </div>
    <div v-else>
      <p class="text-lg">Aucun résultat disponible pour l'instant.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import ResultCard from './ResultCard.vue';

export default defineComponent({
  name: 'ResultsList',
  components: {
    ResultCard
  },
  setup() {
    const store = useStore();
    const results = computed(() => store.getters['results/results'] || []);

    const sortedResults = computed(() => {
      return [...results.value].sort((a, b) => b.score - a.score);
    });

    return {
      results,
      sortedResults
    };
  }
});
</script>
