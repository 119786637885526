<template>
  <section class="mb-8">
    <h2 class="text-2xl font-bold mb-4">Élections</h2>
    <div v-if="elections.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <ElectionCard v-for="election in elections" :key="election.id" :election="election" />
    </div>
    <p v-else class="text-lg">Aucune élection disponible pour l'instant.</p>
  </section>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import ElectionCard from '@/components/ElectionCard.vue';

export default defineComponent({
  name: "ElectionsComponent",
  components: {
    ElectionCard,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch('elections/fetchElections');
    });

    const elections = computed(() => store.getters['elections/elections']);

    return {
      elections,
    };
  },
});
</script>

<style scoped>
h2 {
  color: #2c3e50;
}
p,
ul {
  color: #34495e;
}
</style>
