import { Module } from "vuex";
import { ResponseData } from "@/models";
import api from "@/services/api";
import { Question } from "@/models/Question";

interface QuestionsState {
  questions: Question[];
  responses: ResponseData[];
}

const questions: Module<QuestionsState, unknown> = {
  namespaced: true,
  state: {
    questions: [],
    responses: [],
  },
  mutations: {
    setQuestions(state, questions: Question[]) {
      state.questions = questions;
    },
    setResponses(state, responses: ResponseData[]) {
      state.responses = responses;
    },
    updateResponse(state, { index, answer }: { index: number, answer: any }) {
      if (state.responses[index]) {
        state.responses[index].answer = answer;
      } else {
        state.responses[index] = {
          question_id: state.questions[index].id,
          answer
        };
      }
    }
  },
  actions: {
    async fetchQuestions({ commit }, electionId: number) {
      const questions = await api.fetchQuestions(electionId);
      commit("setQuestions", questions);
      commit(
          "setResponses",
          questions.map((question: Question) => ({
            question_id: question.id,
            answer: "",
          }))
      );
    },
    async submitResponses({ state }, electionId: number) {
      const visitor_id = localStorage.getItem("visitor_id")!;
      await api.submitResponses(visitor_id, state.responses, electionId);
    },
  },
  getters: {
    questions: (state: QuestionsState) => state.questions,
    responses: (state: QuestionsState) => state.responses,
  }
};

export default questions;
