<template>
  <div class="flex justify-between">
    <button @click="$emit('prevQuestion')" :disabled="currentQuestionIndex === 0" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Précédent</button>
    <button v-if="!isLastQuestion" @click="$emit('nextQuestion')" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Suivant</button>
    <button v-if="isLastQuestion" @click="$emit('submitQuiz')" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Soumettre</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QuizNavigation',
  props: {
    currentQuestionIndex: {
      type: Number,
      required: true
    },
    isLastQuestion: {
      type: Boolean,
      required: true
    }
  }
});
</script>
