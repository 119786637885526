<template>
  <div>
    <h1>Contact</h1>
    <form @submit.prevent="submitContactForm" :action="formspreeUrl" method="POST">
      <div>
        <label for="name">Nom</label>
        <input type="text" id="name" v-model="name" name="name" required />
      </div>
      <div>
        <label for="email">Email</label>
        <input type="email" id="email" v-model="email" name="email" required />
      </div>
      <div>
        <label for="subject">Sujet</label>
        <input type="text" id="subject" v-model="subject" name="subject" required />
      </div>
      <div>
        <label for="message">Message</label>
        <textarea id="message" v-model="message" name="message" required></textarea>
      </div>
      <button type="submit">Envoyer</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "ContactView",
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      formspreeUrl: "https://formspree.io/f/movajlej"
    };
  },
  methods: {
    async submitContactForm() {
      try {
        const response = await fetch(this.formspreeUrl, {
          method: 'POST',
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          alert('Message envoyé avec succès!');
          this.resetForm();
        } else {
          throw new Error('Erreur lors de l\'envoi du message');
        }
      } catch (error) {
        console.error('Erreur:', error);
        alert('Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer.');
      }
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.subject = "";
      this.message = "";
    }
  }
};
</script>

<style scoped>
/* Votre style actuel */
</style>