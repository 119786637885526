import axios from "axios";
import { Question } from "@/models/Question";
import { Result } from "@/models/Result";
import { ResponseData } from "@/models";
import { Election } from "@/models/Election";

const API_URL = process.env.VUE_APP_API_BASE_URL || 'http://localhost:8000/api';

export default {
  async fetchQuestions(electionId: number): Promise<Question[]> {
    const response = await axios.get<Question[]>(`${API_URL}/questions/?election=${electionId}`);
    return response.data;
  },
  async submitResponses(visitor_id: string, responses: ResponseData[], electionId: number): Promise<void> {
    await axios.post(`${API_URL}/submit_responses/`, { visitor_id, responses, electionId });
  },
  async fetchResults(visitor_id: string, election_id: number): Promise<Result[]> {
    const response = await axios.get<Result[]>(`${API_URL}/get_results/${visitor_id}/${election_id}/`);
    return response.data;
  },
  async askQuestion(question: string): Promise<string> {
    const response = await axios.post<{ answer: string }>(`${API_URL}/ask_question/`, { question });
    return response.data.answer;
  },
  async getElections(): Promise<Election[]> {
    const response = await axios.get<Election[]>(`${API_URL}/elections/`);
    return response.data;
  },
  async getElection(id: number): Promise<Election[]> {
    const response = await axios.get<Election[]>(`${API_URL}/elections/${id}/`);
    return response.data;
  }
};
