<template>
  <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white p-5">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{ election.country }} - {{ election.name }}</div>
      <p class="text-gray-700 text-base">
        Date: {{ election.date }}
      </p>
      <p class="text-gray-700 text-base">
        Tour: {{ election.round }}
      </p>
    </div>
    <div class="px-6 pt-4 pb-2">
      <router-link
          :to="{ name: 'quiz', params: { electionId: election.id } }"
          class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
      >
        Commencer le quiz
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ElectionCard',
  props: {
    election: {
      type: Object as PropType<{
        id: number;
        name: string;
        country: string;
        date: string;
        round: string;
      }>,
      required: true
    }
  }
});
</script>

<style scoped>
.font-bold {
  color: #2c3e50;
}
.text-gray-700 {
  color: #34495e;
}
</style>
