<template>
  <div>
    <h1>À propos</h1>
    <p>
      Pour Qui Je Vote est une plateforme conçue pour aider les citoyens à
      choisir le candidat politique qui correspond le mieux à leurs opinions.
    </p>
    <p>
      Notre mission est de fournir des informations claires et concises sur les
      candidats et leurs politiques.
    </p>
  </div>
</template>

<script>
export default {
  name: "AboutView",
};
</script>

<style scoped>
h1 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

p {
  font-size: 1.2em;
  margin-bottom: 1em;
}
</style>
