<template>
  <div class="container mx-auto p-4">
    <header class="mb-6">
      <h1 class="text-4xl font-bold mb-2">Pour Qui Je Vote</h1>
      <p class="text-lg">
        Bienvenue sur Pour Qui Je Vote, la plateforme qui vous aide à déterminer
        le candidat politique qui correspond le mieux à vos opinions.
      </p>
    </header>

    <Elections />

    <section class="mb-8">
      <h2 class="text-2xl font-bold mb-4">À propos de nous</h2>
      <p class="mb-4">
        Pour Qui Je Vote est une initiative visant à fournir aux citoyens une
        manière simple et efficace de découvrir quel candidat politique
        correspond le mieux à leurs convictions. Grâce à notre quiz détaillé,
        vous pouvez explorer les différentes propositions des candidats et voir
        lequel s'aligne le plus avec vos opinions.
      </p>
      <p class="mb-4">
        Notre objectif est de rendre le processus électoral plus transparent et
        accessible pour tous. En répondant à une série de questions sur des
        sujets importants, vous pouvez obtenir une recommandation personnalisée
        basée sur les programmes des candidats.
      </p>
      <p class="mb-4">
        En plus des programmes électoraux, nous nous basons également sur les
        votes effectués sur les lois au Sénat pour vous offrir une analyse plus
        complète et précise des positions politiques des candidats. Cela permet
        de mieux comprendre leurs engagements réels et leur cohérence avec leurs
        propositions.
      </p>
      <router-link
          to="/methodologie"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 inline-block"
      >En savoir plus sur notre méthodologie</router-link
      >
    </section>

    <section class="mb-8">
      <h2 class="text-2xl font-bold mb-4">Comment ça marche ?</h2>
      <ol class="list-decimal list-inside mb-4">
        <li class="mb-2">Cliquez sur une élection pour commencer le quiz.</li>
        <li class="mb-2">
          Répondez aux questions en choisissant les réponses qui correspondent
          le mieux à vos opinions.
        </li>
        <li class="mb-2">
          Soumettez vos réponses pour voir quel candidat correspond le mieux à
          vos choix.
        </li>
        <li class="mb-2">
          Explorez les résultats détaillés pour comprendre les positions des
          différents candidats sur les sujets qui vous importent.
        </li>
      </ol>
      <p class="mb-4">
        Notre algorithme compare vos réponses avec les programmes des candidats
        et calcule un score de compatibilité pour chaque candidat. Cela vous
        permet de prendre une décision informée lors des élections.
      </p>
    </section>

    <section>
      <h2 class="text-2xl font-bold mb-4">Notre équipe</h2>
      <p class="mb-4">
        Nous sommes une équipe de passionnés de politique et de technologie,
        dédiée à rendre l'information politique plus accessible. Notre mission
        est de vous fournir des outils fiables et impartiaux pour vous aider à
        prendre des décisions éclairées lors des élections.
      </p>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Elections from '@/components/Elections.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    Elections,
  },
});
</script>

<style scoped>
h1,
h2 {
  color: #2c3e50;
}
p,
ol {
  color: #34495e;
}
blockquote {
  font-size: 1.1em;
  line-height: 1.6;
  margin: 0;
  padding: 0 1em;
  quotes: "“" "”" "‘" "’";
}
blockquote:before {
  content: open-quote;
}
blockquote:after {
  content: close-quote;
}
</style>
