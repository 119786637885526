import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2a9e0c51"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-6 p-4 border rounded-lg shadow-md bg-white" }
const _hoisted_2 = { class: "flex flex-col md:flex-row items-start md:items-center" }
const _hoisted_3 = { class: "text-lg mb-2 md:mb-0 md:mr-4 flex-1 font-semibold text-gray-900" }
const _hoisted_4 = ["name", "id", "value", "checked", "onChange"]
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 0,
  class: "text-red-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.question.text), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('askQuestion', _ctx.question))),
        class: "ml-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mt-2 md:mt-0"
      }, "Besoin d'explication ?")
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.choices, (choice, key) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: "mb-2 flex items-center"
      }, [
        _createElementVNode("input", {
          type: "radio",
          name: 'question-' + _ctx.index,
          id: key,
          value: key,
          checked: _ctx.responses[_ctx.index]?.answer === key,
          onChange: ($event: any) => (_ctx.$emit('updateResponse', { index: _ctx.index, key })),
          class: "mr-2"
        }, null, 40, _hoisted_4),
        _createElementVNode("label", {
          for: key,
          class: "mr-2 cursor-pointer"
        }, _toDisplayString(choice), 9, _hoisted_5)
      ]))
    }), 128)),
    (_ctx.showError && (_ctx.responses[_ctx.index]?.answer == '' || _ctx.responses[_ctx.index]?.answer == null))
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Un choix est requis."))
      : _createCommentVNode("", true)
  ]))
}