<template>
  <div class="container mx-auto p-4">
    <QuizIntro v-if="!started" :election="election" @startQuiz="startQuiz" />
    <div v-if="started && questions.length > 0">
      <h1 class="text-3xl font-bold mb-4">Quiz</h1>
      <div class="w-full bg-gray-200 rounded-full h-4 mb-4">
        <div class="bg-blue-500 h-4 rounded-full" :style="{ width: progress + '%' }"></div>
      </div>
      <QuizQuestion :question="currentQuestion" :index="currentQuestionIndex" :responses="responses" :showError="showError" @askQuestion="askQuestion" @updateResponse="updateResponse" />
      <QuizNavigation :currentQuestionIndex="currentQuestionIndex" :isLastQuestion="isLastQuestion" @prevQuestion="prevQuestion" @nextQuestion="nextQuestion" @submitQuiz="submitQuiz" />
      <AskQuestionModal v-if="showModal" @close="closeModal" :question="currentQuestion" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import QuizIntro from '@/components/quiz/QuizIntro.vue';
import QuizQuestion from '@/components/quiz/QuizQuestion.vue';
import QuizNavigation from '@/components/quiz/QuizNavigation.vue';
import AskQuestionModal from '@/components/quiz/AskQuestionModal.vue';
import utils from "@/utils";

export default defineComponent({
  name: 'QuizView',
  components: {
    QuizIntro,
    QuizQuestion,
    QuizNavigation,
    AskQuestionModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const electionId = Number(route.params.electionId);

    const showModal = ref(false);
    const started = ref(false);
    const currentQuestionIndex = ref(0);
    const showError = ref(false);
    const currentQuestion = ref(null);

    onMounted(() => {
      if (!localStorage.getItem('visitor_id')) {
        localStorage.setItem('visitor_id', utils.generateUUI());
      }
      store.dispatch('questions/fetchQuestions', electionId);
      store.dispatch('elections/fetchElection', electionId);
    });

    const startQuiz = () => {
      started.value = true;
    };

    const askQuestion = (question: any) => {
      currentQuestion.value = question;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const nextQuestion = () => {
      if (responses.value[currentQuestionIndex.value]?.answer == null || responses.value[currentQuestionIndex.value]?.answer == '') {
        showError.value = true;
      } else {
        showError.value = false;
        if (currentQuestionIndex.value < questions.value.length - 1) {
          currentQuestionIndex.value++;
        }
      }
    };

    const prevQuestion = () => {
      if (currentQuestionIndex.value > 0) {
        currentQuestionIndex.value--;
      }
    };

    const submitQuiz = async () => {
      if (responses.value[currentQuestionIndex.value]?.answer === null) {
        showError.value = true;
      } else {
        const visitor_id = localStorage.getItem('visitor_id');
        await store.dispatch('questions/submitResponses', electionId);
        await router.push({ path: `/results/${visitor_id}/${electionId}` });
      }
    };

    const updateResponse = ({ index, key }: { index: number, key: any }) => {
      store.commit('questions/updateResponse', { index, answer: key });
    };

    const questions = computed(() => store.getters['questions/questions']);
    const responses = computed(() => store.getters['questions/responses']);
    const election = computed(() => store.getters['elections/election']);
    const currentQuestionComputed = computed(() => questions.value[currentQuestionIndex.value]);
    const isLastQuestion = computed(() => currentQuestionIndex.value === questions.value.length - 1);
    const progress = computed(() => ((currentQuestionIndex.value + 1) / questions.value.length) * 100);

    return {
      questions,
      responses,
      election,
      showModal,
      started,
      currentQuestion: currentQuestionComputed,
      currentQuestionIndex,
      askQuestion,
      closeModal,
      nextQuestion,
      prevQuestion,
      submitQuiz,
      isLastQuestion,
      progress,
      showError,
      startQuiz,
      updateResponse,
    };
  }
});
</script>

<style scoped>
h1,
h2 {
  color: #2c3e50;
}
p,
ol {
  color: #34495e;
}
button {
  transition: background-color 0.3s ease;
}
button:hover {
  background-color: #3b82f6;
}
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  height: 24px;
}
.progress-bar {
  height: 24px;
  background-color: #3b82f6;
  transition: width 0.3s ease;
}
.text-red-500 {
  color: #f56565;
}
</style>
